import api from "../../api";

export class NotesStore {
  constructor(filter) {
    this.filter = filter;

    // standard vuex properties

    this.strict = true;
    this.namespaced = true;

    this.state = {
      notes: [],
      lastError: '',
      notesLoading: false,
    };

    this.mutations = {
      setNotes: (state, newNotes) => {
        state.notes = newNotes;
      },
      setError: (state, error) => {
        state.lastError = error;
      },
      setNotesLoading: (state, bool) => {
        state.notesLoading = bool
      },
    };

    this.actions = {
      getList: async ({commit}, data) => {
        commit("setNotesLoading", true);
        await api.notes.noteListAll({...data, ...this.filter})
          .then(resolve => {
            commit("setNotes", resolve);
            commit("setNotesLoading", false);
          })
          .catch(e => {
            commit("setNotesLoading", false);
            commit("setError", e.data);
          });
      },
      getListMy: async ({commit}, data) => {
        commit("setNotesLoading", true);
        await api.notes.noteList({...data, ...this.filter})
          .then(resolve => {
            commit("setNotes", resolve);
            commit("setNotesLoading", false);
          })
          .catch(e => {
            commit("setNotesLoading", false);
            commit("setError", e.data);
          });
      }
    };
  }
}
